import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faHome, faCog, faPlay, faPause, faRedo, faStopwatch} from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import parse, { domToReact } from 'html-react-parser';
import '../styles/KelimeBulmaOyunu.css';

const GameBoard = ({
  stage,
  handleReturnToPreviousStage,
  openSettings,
  hideSettings = false,
  paragraph,
  selectedWords,
  targetWords,
  handleWordClick,
  correctWords,
  timeRemaining,
  isPlaying,
  handlePlayPause,
  handleReset
}) => {
  // Router navigate
  const navigate = useNavigate();

  // Geri Dön butonu mantığı
  const handleBackButton = () => {
    if (stage) {
      handleReturnToPreviousStage();
    } else {
      navigate(-1);
    }
  };

  const backButtonLabel = stage ? 'Önceki Aşamaya Dön' : 'Geri Dön';

  // voidElements: HTML içerisinde çocuk düğümü olmayan etiketler
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'keygen',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);

  let wordCounter = 0;

  // html-react-parser ayarları
  const options = {
    replace: (domNode) => {
      if (domNode.type === 'tag') {
        if (voidElements.has(domNode.name)) {
          return React.createElement(
            domNode.name,
            { key: domNode.name + wordCounter, ...domNode.attribs },
            null
          );
        } else {
          return React.createElement(
            domNode.name,
            { key: domNode.name + wordCounter, ...domNode.attribs },
            domToReact(domNode.children, options)
          );
        }
      } else if (domNode.type === 'text') {
        const textContent = domNode.data;
        const words = textContent.split(/(\s+)/);

        const newNodes = [];
        words.forEach((word) => {
          if (word.trim() === '') {
            // Boşluk
            newNodes.push(word);
          } else {
            const originalWord = word;
            const normalizedWord = word
              .toLowerCase()
              .replace(/[^\p{L}\p{N}_]+/gu, '');

            const isSelected = selectedWords.includes(normalizedWord);
            const isCorrect = targetWords.includes(normalizedWord);

            const className = `kbo-word ${
              isSelected
                ? isCorrect
                  ? 'correct'
                  : 'incorrect'
                : ''
            }`;

            newNodes.push(
              <span
                key={`word-${wordCounter}`}
                className={className}
                onClick={() => handleWordClick(normalizedWord)}
              >
                {originalWord}
              </span>
            );
            wordCounter++;
          }
        });

        return <>{newNodes}</>;
      }
    },
  };


  const sanitizedHTML = DOMPurify.sanitize(paragraph);

  return (
    <div className="      
        game-container-bg
        flex
        flex-col
        min-h-screen
        w-full
        max-w-full
        mx-auto
        overflow-hidden
        bg-white
        relative">
      {/* ============= ÜST BAR ============= */}
      <header className="bg-[#2F3E50] text-white w-full h-16 flex items-center justify-between px-4 sm:px-6 lg:px-8">
        {/* Sol taraf (Geri / Ana Sayfa) */}
        <div className="flex items-center gap-4">
          {/* Geri Dön Butonu */}
          <button
            onClick={handleBackButton}
            title={backButtonLabel}
            className="hover:text-yellow-300"
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>

          {/* Ana Sayfa Butonu */}
          <button
            onClick={() => navigate('/')}
            title="Ana Sayfa"
            className="hover:text-yellow-300"
          >
            <FontAwesomeIcon icon={faHome} size="lg" />
          </button>
        </div>

        {/* Sağ taraf (Ayarlar) */}
        {!hideSettings && (
          <button
            onClick={openSettings}
            title="Ayarlar"
            className="hover:text-yellow-300"
          >
            <FontAwesomeIcon icon={faCog} size="lg" />
          </button>
        )}
      </header>

      {/* ============= ORTA KISIM ============= */}
      <div
        className="
          w-full
          max-w-7xl
          mx-auto
          px-4
          sm:px-6
          md:px-8
          flex
          flex-col
          items-center
          pt-4
          pb-12
        "
      >
        {/* Hedef Kelimeler */}
        <div
          className="
       
            flex
            flex-wrap
            items-center
            justify-center
            gap-2
            text-sm
            sm:text-base
            md:text-lg
            lg:text-xl
            xl:text-2xl
          "
        >
          {targetWords.map((word, index) => {
            const isFound = correctWords.includes(word);
            return (
              <span
                key={index}
                className={`kbo-target-word ${isFound ? 'found' : ''}`}
              >
                {word}
              </span>
            );
          })}
        </div>

        {/* Paragraf (Oyun) İçeriği */}
        <div
          className="
            w-full
            mt-6
            p-2
            rounded
            overflow-auto
            flex-1
            bg-gray-50
            text-sm
            sm:text-base
            md:text-lg
            lg:text-xl
            xl:text-2xl      
            max-h-[calc(100vh-18rem)]
            portrait:max-h-[calc(100vh-19rem)]
            landscape:max-h-[calc(80vh-10rem)]
          "       
        >
          {paragraph ? (
            <div>{parse(sanitizedHTML, options)}</div>
          ) : (
            <div className="kbo-message-display text-center text-red-600">
              Seçili paragrafta içerik bulunamadı. Lütfen farklı bir paragraf seçin.
            </div>
          )}
        </div>
      </div>

   
      <div className="w-full h-auto mt-auto relative">
        {/* Dalga SVG */}
        <div className="absolute top-0 left-0 w-full overflow-hidden leading-[0]">
          <svg
            className="block w-full h-[60px]"
            viewBox="0 0 1440 320"
            preserveAspectRatio="none"
          >
            <path
              fill="#1E293B"
              d="M0,288L34.3,266.7C68.6,245,137,203,206,170.7C274.3,139,343,117,411,128C480,139,549,181,617,213.3C685.7,245,754,267,823,240C891.4,213,960,139,1029,128C1097.1,117,1166,171,1234,160C1302.9,149,1371,75,1406,37.3L1440,0L1440,320L1405.7,320C1371.4,320,1302,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,685,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
            />
          </svg>
        </div>

        {/* Kontrol Bar */}
        <div
          className="
            relative
            bg-slate-800
            text-white
            flex
            items-center
            justify-evenly
            gap-3
            py-2
            px-3
            rounded-t-3xl
            z-10
          "
          style={{ marginTop: '-30px' }}
        >
          {/* Başlat / Duraklat Butonu */}
          <button
            onClick={handlePlayPause}
            title={isPlaying ? 'Duraklat' : 'Başlat'}
            className="
              hover:text-yellow-300
              text-xs sm:text-sm md:text-base
              flex flex-col items-center
              focus:outline-none
            "
          >
            <FontAwesomeIcon
              icon={isPlaying ? faPause : faPlay}
              className="text-xl sm:text-2xl md:text-3xl mb-1"
            />
            <span className="text-[0.6rem] sm:text-xs md:text-sm">
              {isPlaying ? 'Duraklat' : 'Başlat'}
            </span>
          </button>

          {/* Yeniden Başlat Butonu */}
          <button
            onClick={handleReset}
            title="Yeniden Başlat"
            className="
              hover:text-yellow-300
              text-xs sm:text-sm md:text-base
              flex flex-col items-center
              focus:outline-none
            "
          >
            <FontAwesomeIcon
              icon={faRedo}
              className="text-xl sm:text-2xl md:text-3xl mb-1"
            />
            <span className="text-[0.6rem] sm:text-xs md:text-sm">
              Yeniden Başlat
            </span>
          </button>

          {/* Süre Göstergesi */}
          <div className="flex flex-col items-center" title="Kalan Süre">
            <FontAwesomeIcon
              icon={faStopwatch}
              className="text-xl sm:text-2xl md:text-3xl mb-1"
            />
            <div className="text-[0.6rem] sm:text-xs md:text-sm">
              {timeRemaining} sn
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameBoard;
