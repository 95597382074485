import React, { useState, useEffect } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GameHeader from './components/GameHeader';
import ProgressBar from './components/ProgressBar';
import GameTimer from './components/GameTimer';
import GameOverModal from './components/GameOverModal';
import GameSettingsModal from './components/GameSettingsModal'; 
import { useSilinenYaziOyunu } from './hooks/useSilinenYaziOyunu';
import './styles/silinenYaziStyle.css';

const SilinenYaziOyunu = () => {
  const navigate = useNavigate();
  const [stage, setStage] = useState('');
  const token = localStorage.getItem('userToken');

  // Intro ekranını kontrol eden state
  const [showIntroduction, setShowIntroduction] = useState(true);
  const enterGame = () => {
    setShowIntroduction(false);
  };

 
  const {
    userData,
    userRole,
    paragraphs,
    selectedParagraphIndex,
    setSelectedParagraphIndex,
    difficulty,
    handleDifficultyChange,
    handleParagraphChange,
    theme: gameTheme, 
    handleThemeChange: handleGameThemeChange,
    speedFactor,
    setSpeedFactor,
    timeLeft,
    gizlenenKelimeSayisi,
    calisiyorMu,
    oyunBitti: oyunGercektenBitti,
    progress,
    handlePlayPause: hookPlayPause,
    handleReset,
    kelimeler,
  } = useSilinenYaziOyunu(token);

  // Oyunun "başladı mı" durumu
  const [oyunBasladi, setOyunBasladi] = useState(false);
  const oyunBitti = oyunBasladi && oyunGercektenBitti;
  const [showGameOver, setShowGameOver] = useState(false);

  // Ayarlar Modal
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  // Modal açma/kapama (Game Over)
  useEffect(() => {
    if (!oyunBasladi) return;
    if (oyunBitti && !showGameOver) {
      setShowGameOver(true);
    } else if (!oyunBitti && showGameOver) {
      setShowGameOver(false);
    }
  }, [oyunBitti, showGameOver, oyunBasladi]);

  // Modal kapatma
  const handleCloseModal = () => {
    setShowGameOver(false);
    setOyunBasladi(false);
    handleReset();
  };

  // Oyunu yeniden başlatma
  const handleRestartGame = () => {
    setShowGameOver(false);
    setOyunBasladi(false);
    handleReset();
  };

  // Bir önceki aşamaya dönme
  const handleReturnToPreviousStage = () => {
    navigate(-1);
  };

  // Play/Pause butonu
  const handlePlayPause = () => {
    if (!oyunBasladi) {
      setOyunBasladi(true);
    }
    if (oyunBitti) return;
    hookPlayPause();
  };

  // HTML içerisindeki kelimeleri parçalayıp gizleme ayarları
  const voidElements = new Set([
    'area', 'base', 'br', 'col', 'embed', 'hr',
    'img', 'input', 'keygen', 'link', 'meta',
    'param', 'source', 'track', 'wbr',
  ]);

  let globalWordCounter = 0;
  const parseOptions = {
    replace: (node) => {
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            null
          );
        } else {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            domToReact(node.children, parseOptions)
          );
        }
      }
      if (node.type === 'text') {
        const rawWords = node.data.split(' ');

        const replacedWords = rawWords.map((word, idx) => {
          const isHidden =
            globalWordCounter < gizlenenKelimeSayisi && word.trim() !== '';
          globalWordCounter++;

          const wordWithSpace = idx < rawWords.length - 1 ? word + ' ' : word;

          return (
            <span
              key={`word-${globalWordCounter}`}
              style={{
                visibility: isHidden ? 'hidden' : 'visible',
                marginRight: '2px',
              }}
            >
              {wordWithSpace}
            </span>
          );
        });

        return <>{replacedWords}</>;
      }
      return undefined;
    },
  };

  // Şu anki paragrafın içeriği
  const currentContent = paragraphs[selectedParagraphIndex]?.content || '';

  // ============== TEMA, FONT, FONTSIZE ==============
  const [appTheme, setAppTheme] = useState('default');
  const [font, setFont] = useState('Arial');
  const [fontSize, setFontSize] = useState('1rem');

  const handleAppThemeChange = (e) => setAppTheme(e.target.value);
  const handleFontChange = (e) => setFont(e.target.value);
  const handleFontSizeChange = (e) => setFontSize(e.target.value);

  // Hız arttırma/azaltma
  const handleSpeedIncrease = () => {
    setSpeedFactor((prev) => Math.min(prev + 0.1, 6));
  };
  const handleSpeedDecrease = () => {
    setSpeedFactor((prev) => Math.max(prev - 0.1, 0.1));
  };

  return (
    <>

      {showIntroduction ? (
  <div
  className="
    game-container-bg
    silinen-yazi-theme-default
    min-h-screen
    w-full
    px-4
    sm:px-6
    md:px-8
    lg:px-12
    py-8
    flex
    flex-col
    items-center
    lg:items-start
  "
  style={{ fontFamily: font, fontSize: '16px' }}
>
  <div
    className="
      intro-container
      w-full
      max-w-3xl
      mx-auto
      mt-8
      px-4
      sm:px-6
      transform
      origin-top
      transition
      duration-300
      ease-in-out
      scale-100
     
    "
  >
    <GameHeader
      stage={stage}
      handleReturnToPreviousStage={handleReturnToPreviousStage}
      onOpenSettings={() => setShowSettingsModal(true)}
      hideSettings={true}
    />

    <h1 className="intro-title mt-4 text-2xl font-bold mb-2  text-center">
      Silinen Yazılar
    </h1>
    <p className="intro-description text-base mb-4">
      Bu çalışmada, belirli kelimeler kısa süre içinde görünmez hale gelecek.
      Metni takip etmeye devam edebilir misiniz? Zamanla daha fazla kelime
      kaybolacak, bakalım ne kadarını hatırlayacaksınız!
    </p>

    {/* Talimatlar kartı */}
    <div
      className="
        intro-instructions-box
        bg-white
        p-6
        rounded-md
        shadow
        w-full
        max-w-xl
        mb-4
        mx-auto  /* EKLENDİ: Kartı yatayda ortalamak için */
      "
    >
      <h3
        className="
          intro-instructions-title
          text-lg
          font-semibold
          mb-3
          text-[#211C84]
        "
      >
        Talimatlar
      </h3>
      <ul
        className="
          intro-instructions-list
          list-none
          text-sm
          text-gray-700
          space-y-2
        "
      >
        <li>1. Ayarlar menüsünden zorluk ve paragraf seç.</li>
        <li>2. "Başlat" tuşuna bas ve metni dikkatlice takip et.</li>
        <li>3. Bazı kelimeler silinmeye başlayacak. Takibi bırakma!</li>
        <li>4. Süre veya tüm kelimelerin silinmesiyle çalışma biter.</li>
      </ul>
    </div>

        <div className=' text-center'>
        <button
      className="
        intro-button
        bg-[var(--button-background)]
        hover:bg-[var(--button-hover)]
        text-white
        font-bold
        px-5
        py-2
        rounded-md
        shadow-lg
        transition-transform
        duration-100
        active:scale-95
        mt-2
        mb-12
         
      "
      onClick={enterGame}
    >
      BAŞLAT
    </button>
        </div>
 
  </div>
</div>

       
      ) : (
        /** ------------------------------------ 
            OYUN EKRANI 
            ------------------------------------ */
            <div
            className={`
              silinen-yazi-game-container
              game-container-bg
              silinen-yazi-theme-${appTheme}
              flex
              flex-col
              min-h-screen
              px-4
              py-4
              md:px-8
              md:py-6
         
              text-base  
              sm:text-lg   
              md:text-xl   
              lg:text-2xl 
            `}
            style={{ fontFamily: font }} 
          >
            {/* Header */}
            <GameHeader
              stage={stage}
              handleReturnToPreviousStage={handleReturnToPreviousStage}
              onOpenSettings={() => setShowSettingsModal(true)}
            />
          
            {/* Timer ve ProgressBar üst blok */}
            <div 
              className="
                w-full 
                flex 
                flex-col 
                items-center 
                justify-center 
                mt-4
              "
            >
              <GameTimer timeLeft={timeLeft} />
              <div className="w-full max-w-3xl mt-4">
                <ProgressBar progress={progress} />
              </div>
            </div>
          
            {/* Butonlar */}
            <div 
              className="
                game-buttons 
                flex 
                flex-wrap 
                items-center 
                justify-center 
                gap-4 
                mt-6
              "
            >
              <button
                onClick={handlePlayPause}
                className="
                  game-button
                  bg-blue-600
                  hover:bg-blue-700
                  text-white
                  py-2
                  px-4
                  rounded
                "
                disabled={oyunBitti}
              >
                <FontAwesomeIcon icon={calisiyorMu ? faPause : faPlay} /> 
                {calisiyorMu ? ' Duraklat' : ' Başlat'}
              </button>
          
              <button
                onClick={handleRestartGame}
                className="
                  game-button
                  bg-gray-600
                  hover:bg-gray-700
                  text-white
                  py-2
                  px-4
                  rounded
                "
              >
                <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
              </button>
            </div>
          
            {/* Hız Ayarı */}
            <div 
              className="
                silinen-yazi-controls 
                flex 
                flex-col 
                items-center 
                justify-center 
                mt-6
              "
            >
              <div 
                className="
                  speed-control-container 
                  flex 
                  items-center 
                  gap-2
                "
              >
                <div className="speed-label text-lg font-semibold">
                  Hız Ayarı
                </div>
                <div 
                  className="
                    speed-display 
                    flex 
                    items-center 
                    justify-center 
                    gap-2
                  "
                >
                  <button
                    className="
                      speed-button 
                      bg-red-600 
                      hover:bg-red-700 
                      text-white 
                      py-1 
                      px-3 
                      rounded
                    "
                    onClick={handleSpeedDecrease}
                  >
                    -
                  </button>
                  <div className="speed-value text-lg font-bold">
                    {speedFactor.toFixed(1)}
                  </div>
                  <button
                    className="
                      speed-button 
                      bg-green-600 
                      hover:bg-green-700 
                      text-white 
                      py-1 
                      px-3 
                      rounded
                    "
                    onClick={handleSpeedIncrease}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          
            {/* Metin Gösterimi */}
            <div
  className="
    silinen-yazi-game-display
    w-full
    max-w-8xl   
    mx-auto
    mt-8
    p-4
    rounded
    overflow-auto
    flex-1
  "
>
  <div
    className="silinen-yazi-content"
    key={`${selectedParagraphIndex}-${gizlenenKelimeSayisi}`}
  >
    {parse(currentContent, parseOptions)}
  </div>
</div>

          
            {/* Durum Mesajı */}
            <p 
              className="
                silinen-yazi-message 
                text-center 
                mt-4 
                mb-8 
                text-xl
              "
            >
              {oyunBitti
                ? 'Çalışma Bitti! Süre doldu veya tüm kelimeler silindi.'
                : `Silinen kelime sayısı: ${gizlenenKelimeSayisi}/${kelimeler.length}`
              }
            </p>
          
            {/* Oyun Bittiyse Modal */}
            {showGameOver && (
              <GameOverModal
                score={gizlenenKelimeSayisi}
                elapsedTime={((500 * gizlenenKelimeSayisi) / 1000).toFixed(1)}
                onRestart={handleRestartGame}
                onClose={handleCloseModal}
                userData={userData}
              />
            )}
          
            {/* Ayarlar Modal */}
            <GameSettingsModal
              isOpen={showSettingsModal}
              onClose={() => setShowSettingsModal(false)}
              difficulty={difficulty}
              onDifficultyChange={handleDifficultyChange}
              paragraphs={paragraphs}
              selectedParagraphIndex={selectedParagraphIndex}
              onParagraphChange={setSelectedParagraphIndex}
              appTheme={appTheme}
              onAppThemeChange={handleAppThemeChange}
              font={font}
              onFontChange={handleFontChange}
              fontSize={fontSize}
              onFontSizeChange={handleFontSizeChange}
            />
          </div>
          
      )}
    </>
  );
};

export default SilinenYaziOyunu;
