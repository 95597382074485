import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome, faCog } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const IntroLayout = ({
  stage,
  handleReturnToPreviousStage,
  openSettings,
  hideSettings = false,
  startGame
}) => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (stage) {
      handleReturnToPreviousStage();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      {/* Üst Bar */}
      <header className="bg-[#2F3E50] text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
          {/* Sol taraf (Geri Butonu) */}
          <div className="flex items-center gap-4">
            <button
              onClick={handleBackButton}
              title={stage ? 'Önceki Aşamaya Dön' : 'Geri Dön'}
              className="hover:text-yellow-300"
            >
              <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </button>
            <button
              onClick={() => navigate('/')}
              title="Ana Sayfa"
              className="hover:text-yellow-300"
            >
              <FontAwesomeIcon icon={faHome} size="lg" />
            </button>
          </div>

          {/* Sağ taraf (Ayarlar) */}
          {!hideSettings && (
            <button
              onClick={openSettings}
              title="Ayarlar"
              className="hover:text-yellow-300"
              
            >
              <FontAwesomeIcon icon={faCog} size="lg" />
            </button>
          )}
        </div>
      </header>

      {/* Ana İçerik Alanı */}
      <main className="flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-2xl py-8">
          {/* Başlık */}
          <h1 className="text-center text-2xl font-bold text-[#211C84] mb-4">
            Harf Izgarası Çalışması
          </h1>

          {/* Koşullu Açıklama */}
          {stage ? (
            <p className="text-center text-base text-gray-700 mb-6 px-4">
              Bu çalışmada aşamanıza göre zorluk seviyesi otomatik belirlenecek.
              Verilen süre içinde hedef harfleri ızgarada bulmaya çalışın.
            </p>
          ) : (
            <p className="text-center text-base text-gray-700 mb-6 px-4">
              Bu çalışmanın amacı, verilen süre içerisinde hedef harfleri ızgarada bulmaktır.
            </p>
          )}

          {/* Talimatlar Kutusu */}
          <div className="bg-white rounded-md shadow p-6 mb-6 mx-auto">
            <h3 className="text-lg font-semibold mb-3 text-[#211C84]">
              Talimatlar
            </h3>
            <ul className="list-none text-sm text-gray-700 space-y-2">
              <li>1. Verilen hedef harfleri ızgarada bulup tıklayın.</li>
              <li>2. Her doğru seçim için puan kazanırsınız.</li>
              <li>3. Süre dolduğunda çalışma sona erer.</li>
            </ul>
          </div>

          {/* Başlat Butonu */}
          <div className="flex justify-center">
            <button
              onClick={startGame}
              className="
                bg-blue-600
                hover:bg-blue-700
                text-white
                font-bold
                py-2
                px-6
                rounded-md
                shadow
                active:scale-95
                transition-transform
                duration-100
              "
            >
              BAŞLAT
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default IntroLayout;
