import React from 'react';

/**
 * Harf Izgarası'ndaki "IntroLayout" benzeri bir stil kullanıyoruz.
 */
const Instructions = ({ onStart }) => {
  return (
    <div className="max-w-lg mx-auto mt-8 p-4 bg-white rounded shadow">
      <h2 className="text-2xl font-bold mb-4 text-center text-[#211C84]">
        Sınava Hoş Geldiniz
      </h2>

      <p className="text-gray-700 text-center mb-4">
        Bu sınav, okuma hızınızı ölçmek için tasarlanmıştır. Karşınıza çıkacak
        metni mümkün olan en kısa sürede okuyun. "Sınavı Başlat" butonuna
        bastığınızda zamanlayıcı başlayacak ve metin görünecektir. Okumayı
        bitirdiğinizde "Okumayı Bitirdim" butonuna basarak sonucu görebilirsiniz.
      </p>

      <div className="bg-gray-100 p-3 rounded-md mb-4">
        <h3 className="text-lg font-semibold mb-2 text-[#211C84]">Talimatlar</h3>
        <ul className="list-disc list-inside text-sm text-gray-700 space-y-1">
          <li>Metni dikkatlice okuyun.</li>
          <li>Sınavı başlatmak için butona tıklayın.</li>
          <li>Okuma sonunda sonucu görebilirsiniz.</li>
        </ul>
      </div>

      <div className="flex justify-center">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded transition-transform duration-100 active:scale-95"
          onClick={onStart}
        >
          Sınavı Başlat
        </button>
      </div>
    </div>
  );
};

export default Instructions;
