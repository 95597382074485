import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome, faCog } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const HeaderSection = ({
  stage,
  handleReturnToPreviousStage,
  openSettings,
  hideSettings
}) => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (stage) {
      handleReturnToPreviousStage();
    } else {
      navigate(-1);
    }
  };

  return (
    // Üstte yatay bar: yükseklik sabit (h-16), tam genişlik (w-full).
    <div className="h-16 w-full bg-slate-700 text-white flex items-center justify-center gap-6">
      {/* Geri Dön Butonu */}
      <button
        onClick={handleBackButton}
        title={stage ? 'Önceki Aşamaya Dön' : 'Geri Dön'}
        className="hover:text-yellow-300"
      >
        <FontAwesomeIcon icon={faArrowLeft} size="lg" />
      </button>

      {/* Ana Sayfa Butonu */}
      <button
        onClick={() => navigate('/')}
        title="Ana Sayfa"
        className="hover:text-yellow-300"
      >
        <FontAwesomeIcon icon={faHome} size="lg" />
      </button>

      {/* Ayarlar Butonu (hideSettings false ise göster) */}
      {!hideSettings && (
        <button
          onClick={openSettings}
          title="Ayarlar"
          className="hover:text-yellow-300"
        >
          <FontAwesomeIcon icon={faCog} size="lg" />
        </button>
      )}
    </div>
  );
};

export default HeaderSection;
