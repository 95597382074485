import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useKelimeTamamlamaOyunu } from './hooks/useKelimeTamamlamaOyunu';
import Header from './components/Header';
import GameDisplay from './components/GameDisplay';
import Message from './components/Message';
import Description from './components/Description';
import GameOverModal from './components/GameOverModal';
import SettingsModalKelime from './components/SettingsModalKelime';
import './styles/KelimeTamamlamaOyunu.css';
import axios from 'axios';
import axiosInstance from '../../../utils/axiosInstance';
import { API_URLS } from '../../../config/config';

const KelimeTamamlamaOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOverModal, setShowGameOverModal] = useState(false);

  // AYARLAR MODALI
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  // Aşamaya göre zorluk
  const stageNumber = stage?.number || null;
  let initialDifficulty = 'easy';
  let difficultyLocked = false;

  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  // Tema, font, boyut
  const [theme, setTheme] = useState('default');
  const [font, setFont] = useState('Arial');
  const [fontSize, setFontSize] = useState('1rem');

  // Hız ayarı (GameDisplay'e vereceğiz)
  const [speedFactor, setSpeedFactor] = useState(1);

  // useKelimeTamamlamaOyunu Hook
  const {
    difficulty,
    setDifficulty,
    paragraphsList,
    selectedParagraphIndex,
    setSelectedParagraphIndex,
    paragraph,
    options,
    userSelections,
    isPlaying,
    score,
    message,
    timeRemaining,
    handleWordSelect,
    handleBlankClick,
    handleDifficultyChange: originalHandleDifficultyChange,
    handleParagraphChange,
    getParagraphWithBlanks,
    handlePlayPause,
    handleReset,
  } = useKelimeTamamlamaOyunu(userData, initialDifficulty);

  // Kullanıcı verileri çek
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa öğretmen
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  const startGame = () => {
    setShowInstructions(false);
    handleReset();
  };

  const updateProgress = async () => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Kelime Tamamlama',
        score: score,
        time: (userData && userData.status === 'inactive' ? 15 : 60) - timeRemaining,
        completed: true,
      };

      if (userRole === 'student') {
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }
      console.log('İlerleme başarıyla güncellendi.');
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const onGameOver = () => {
    setShowGameOverModal(true);
    updateProgress();
  };

  const handleRestart = () => {
    setShowGameOverModal(false);
    handleReset();
  };

  // Süre dolunca veya tüm kelimeler doldurulunca
  useEffect(() => {
    if (
      (!isPlaying && timeRemaining === 0) ||
      message === 'Tebrikler! Tüm kelimeleri doldurdunuz.'
    ) {
      onGameOver();
    }
  }, [isPlaying, timeRemaining, message]);

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleClose = () => {
    setShowGameOverModal(false);
  };

  // Zorluk değişikliği (aşama varsa izin verme)
  const handleDifficultyChange = (e) => {
    if (difficultyLocked) return;
    originalHandleDifficultyChange(e);
  };

  return (
    <div
      className={`kt-container game-container-bg kt-theme-${theme}`}
      style={{ fontFamily: font, fontSize }}
    >
      {showInstructions ? (
      <div
      className={`    
      
        min-h-screen
        flex
        flex-col
        items-center
        justify-center    
        lg:justify-start
        lg:pt-10    
        px-4
        sm:px-6
        md:px-8
        lg:px-12
      `}
      style={{ fontFamily: font, fontSize: '16px' }}
    >
      <div className="intro-container mt-4 w-full max-w-2xl mx-auto p-4 md:p-6">
        <Header
          stage={stage}
          handleReturnToPreviousStage={handleReturnToPreviousStage}
          handleSettingsClick={toggleSettingsModal}
          hideSettings={true}
        />
    
        <h1 className="intro-title mt-4 text-2xl font-bold mb-2 text-center">
          Kelime Tamamlama Çalışması
        </h1>
    
        {stage ? (
          <p className="intro-description text-base mb-4">
            Bu çalışmada aşamanıza göre zorluk seviyesi otomatik belirlenecek.
            Cümlelerdeki boşluklara doğru kelimeleri yerleştirin.
          </p>
        ) : (
          <Description />
        )}
    
        <button
          className="
          intro-button
          bg-[var(--button-background)]
          hover:bg-[var(--button-hover)]
          text-white
          font-bold
          px-5
          py-2
          rounded-md
          shadow-lg
          transition-transform
          duration-100
          active:scale-95
          block
          mx-auto
          "
          onClick={startGame}
        >
          BAŞLAT
        </button>
      </div>
    </div>
    
      ) : (
        <>
          <Header
            stage={stage}
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            handleSettingsClick={toggleSettingsModal}
          />
         <div
          className="
            kt-card
            w-full
            max-w-6xl
            mx-auto
            bg-white
            shadow-md
            rounded-lg
            p-4
            mt-4
            text-sm
            sm:text-base
            md:text-lg
            lg:text-xl
            xl:text-2xl
          "
        >
        <div
          className="
            kt-card-body
            p-4
            flex
            flex-col
            gap-4
          "
        >
    <GameDisplay
      paragraph={paragraph}
      getParagraphWithBlanks={getParagraphWithBlanks}
      options={options}
      userSelections={userSelections}
      handleWordSelect={handleWordSelect}
      handleBlankClick={handleBlankClick}
      isPlaying={isPlaying}
      handlePlayPause={handlePlayPause}
      handleReset={handleReset}
      timeRemaining={timeRemaining}
    />

    <Message message={message} timeRemaining={timeRemaining} />
  </div>
</div>

        </>
      )}

      {showGameOverModal && (
        <GameOverModal
          score={score}
          onRestart={handleRestart}
          onClose={handleClose}
          userData={userData}
        />
      )}

      {/* Settings Modal */}
      {showSettingsModal && (
        <SettingsModalKelime
          onClose={() => setShowSettingsModal(false)}
          // Tüm ayarları buradan değiştiriyoruz
          difficulty={difficulty}
          difficultyLocked={difficultyLocked}
          handleDifficultyChange={handleDifficultyChange}
          paragraphsList={paragraphsList}
          selectedParagraphIndex={selectedParagraphIndex}
          handleParagraphChange={handleParagraphChange}
          theme={theme}
          setTheme={setTheme}
          font={font}
          setFont={setFont}
          fontSize={fontSize}
          setFontSize={setFontSize}
        />
      )}
    </div>
  );
};

export default KelimeTamamlamaOyunu;
