// src/components/MessageDisplay.js
import React from 'react';
import '../styles/KelimeBulmaOyunu.css';

const MessageDisplay = ({ message }) => {
  if (!message) return null;

  return (
    <div
      className="
        fixed
        top-16          
        left-1/2
        transform -translate-x-1/2
        z-50           
        bg-yellow-100
        text-yellow-800
        border
        border-yellow-300
        rounded
        shadow-lg
        px-4
        py-2
        max-w-md
        w-auto
        text-center
      "
    >
      {message}
    </div>
  );
};

export default MessageDisplay;
