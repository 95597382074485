import { useState, useEffect } from 'react';

const alphabet = 'ABCÇDEFGĞHIİJKLMNOÖPRSŞTUÜVYZ';

export const useLetterGridGame = (userData, onGameOver, initialDifficulty = 'easy') => {
  const [letters, setLetters] = useState([]);
  const [targetLetters, setTargetLetters] = useState([]);
  const [message, setMessage] = useState('');
  const [score, setScore] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [clickedLetters, setClickedLetters] = useState([]);
  const [difficulty, setDifficulty] = useState(initialDifficulty);
  const [gridSize, setGridSize] = useState(24); 

  const [correctSelections, setCorrectSelections] = useState(0);
  const [incorrectSelections, setIncorrectSelections] = useState(0);

  const [targetLetterCounts, setTargetLetterCounts] = useState({});
  const [foundLetterCounts, setFoundLetterCounts] = useState({});

  // Süre
  const initialTime = !userData || userData.status === 'inactive' ? 15 : 60;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  // Zorluk değişince yeni oyun başlat
  useEffect(() => {
    initializeGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty]);

  // Geri sayım
  useEffect(() => {
    let timer;
    if (isPlaying && timeRemaining > 0) {
      timer = setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000);
    } else if (isPlaying && timeRemaining === 0) {
      setIsPlaying(false);
      setMessage('Süre doldu!');
      if (onGameOver) {
        onGameOver(score, correctSelections, incorrectSelections);
      }
    }
    return () => clearTimeout(timer);
  }, [isPlaying, timeRemaining, score, correctSelections, incorrectSelections, onGameOver]);

  // === Önemli Kısım: size değerlerinin artırılması ===
  const initializeGame = () => {
    let size;
    let targetCount;
    let targetLetterFrequency;

    switch (difficulty) {
      case 'easy':
        // 24 → 28
        size = 36;
        targetCount = 1;
        targetLetterFrequency = 0.1;
        break;
      case 'medium':
        // 32 → 36
        size = 42;
        targetCount = 2;
        targetLetterFrequency = 0.06;
        break;
      case 'hard':
        // 58 → 64
        size = 60;
        targetCount = 3;
        targetLetterFrequency = 0.04;
        break;
      default:
        // Varsayılanı da aynı şekilde artırıyoruz: 24 → 28
        size = 28;
        targetCount = 1;
        targetLetterFrequency = 0.1;
    }

    setGridSize(size);

    // 1) Rastgele hedef harf(ler) seç
    const targets = [];
    for (let i = 0; i < targetCount; i++) {
      let randomLetter;
      do {
        randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
      } while (targets.includes(randomLetter));
      targets.push(randomLetter);
    }
    setTargetLetters(targets);

    // 2) Izgarayı oluştur
    const newLetters = [];
    // 2a) Her hedef harfi en az 1 kez ekle
    for (let i = 0; i < targetCount; i++) {
      newLetters.push(targets[i]);
    }
    // 2b) Kalan hücrelerde harfleri rastgele üret
    for (let i = targetCount; i < size; i++) {
      if (Math.random() < targetLetterFrequency) {
        const randomIndex = Math.floor(Math.random() * targetCount);
        newLetters.push(targets[randomIndex]); 
      } else {
        const randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
        newLetters.push(randomLetter);
      }
    }
    // 2c) Diziyi karıştır
    shuffleArray(newLetters);

    setLetters(newLetters);

    // 3) Her hedef harften kaçar tane var?
    const counts = {};
    targets.forEach((letter) => {
      counts[letter] = newLetters.filter((l) => l === letter).length;
    });
    setTargetLetterCounts(counts);
    setFoundLetterCounts({});

    // 4) State’leri sıfırla
    setClickedLetters([]);
    setCorrectSelections(0);
    setIncorrectSelections(0);
    setScore(0);
    setMessage('');

    // 5) Süreyi en başa al
    setTimeRemaining(initialTime);
  };

  // Yardımcı: Diziyi karıştır
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  // Oyunu başlat/durdur
  const handlePlayPause = () => {
    if (!isPlaying) {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  // Reset işlemi
  const handleReset = () => {
    setIsPlaying(false);
    setScore(0);
    setTimeRemaining(initialTime);
    setCorrectSelections(0);
    setIncorrectSelections(0);
    initializeGame();
  };

  // Harfe tıklama
  const handleLetterClick = (letter, index) => {
    if (!isPlaying || clickedLetters.includes(index)) return;

    setClickedLetters((prevClicked) => [...prevClicked, index]);

    if (targetLetters.includes(letter)) {
      setFoundLetterCounts((prevCounts) => {
        const newCounts = { ...prevCounts };
        newCounts[letter] = (newCounts[letter] || 0) + 1;
        return newCounts;
      });
      setScore((prevScore) => prevScore + 1);
      setCorrectSelections((prev) => prev + 1);
      setMessage('Doğru!');

      // Tüm hedef harfler bulundu mu?
      const newFoundLetterCounts = {
        ...foundLetterCounts,
        [letter]: (foundLetterCounts[letter] || 0) + 1,
      };
      const allFound = targetLetters.every(
        (target) => newFoundLetterCounts[target] >= targetLetterCounts[target]
      );

      if (allFound) {
        if (timeRemaining > 0) {
          setMessage('Tüm harfleri buldunuz! Yeni harflere geçiliyor...');
          setTimeout(() => {
            initializeGame();
          }, 1000);
        } else {
          setIsPlaying(false);
          if (onGameOver) {
            onGameOver(score, correctSelections, incorrectSelections);
          }
        }
      }
    } else {
      // Yanlış tıklama
      setScore((prevScore) => (prevScore > 0 ? prevScore - 1 : 0));
      setIncorrectSelections((prev) => prev + 1);
      setMessage('Yanlış!');
    }
  };

  // Zorluk değiştirme
  const handleDifficultyChange = (newDifficulty) => {
    setDifficulty(newDifficulty);
  };

  return {
    letters,
    targetLetters,
    message,
    timeRemaining,
    score,
    isPlaying,
    clickedLetters,
    difficulty,
    gridSize,
    correctSelections,
    incorrectSelections,
    handlePlayPause,
    handleReset,
    handleLetterClick,
    handleDifficultyChange,
  };
};
