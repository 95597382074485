import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';

/**
 * Harf Izgarası'ndaki "HeaderSection" yapısını klonladık.
 * Bu kez "Ayarlar" butonunu göstermiyoruz. Dilerseniz ekleyebilirsiniz.
 */
const Header = () => {
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);
  const handleHome = () => navigate('/');

  return (
  
    <div className="h-16 w-full bg-slate-700 text-white flex items-center justify-center gap-6">
      {/* Geri Dön Butonu */}
      <button
        onClick={handleBack}
        title="Geri Dön"
        className="hover:text-yellow-300"
      >
        <FontAwesomeIcon icon={faArrowLeft} size="lg" />
      </button>

      {/* Ana Sayfa Butonu */}
      <button
        onClick={handleHome}
        title="Ana Sayfa"
        className="hover:text-yellow-300"
      >
        <FontAwesomeIcon icon={faHome} size="lg" />
      </button>
    </div>
  );
};

export default Header;
