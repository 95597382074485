import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlay, faPause, faRedo, faStopwatch, faStar} from '@fortawesome/free-solid-svg-icons';

const LetterGrid = ({
  letters,
  targetLetters,
  clickedLetters,
  handleLetterClick,
  gridSize,
  isPlaying,
  handlePlayPause,
  handleReset,
  timeRemaining,
  score
}) => {
 
  const [rows, setRows] = useState(3);

  useEffect(() => {

    const mql = window.matchMedia('(orientation: portrait)');

    // Başlangıçta ayarla
    if (mql.matches) {
      setRows(12); // Dikey
    } else {
      setRows(4); // Yatay
    }

    // Değişim dinleyicisi
    const handleOrientationChange = (e) => {
      if (e.matches) {
        // Artık portrait
        setRows(3);
      } else {
        // landscape
        setRows(4);
      }
    };

    // Değişikliği dinlemeye başla
    mql.addEventListener('change', handleOrientationChange);

    // Cleanup
    return () => {
      mql.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  // Sütun sayısı
  const columns = Math.ceil(gridSize / rows);

  return (
    <div className="
      w-full flex flex-col items-center max-w-full overflow-hidden bg-white relative
    ">
      {/* Hedef Harfler Bloğu */}
      <div className="
        w-full
        text-center
        text-[0.75rem]
        sm:text-base
        md:text-lg
        font-semibold
        border-2 
        border-dashed 
        px-2 
        py-1 
        rounded-md 
        mb-4
        text-[var(--text-color)]
        border-[var(--text-color)]
        shadow-sm
      ">
        Hedef Harfler: {targetLetters.join(', ')}
      </div>

      {/* Harf Izgarası */}
      <div
        className="
          w-full
          mx-auto
          border
          border-orange-300
          rounded-xl
          bg-[var(--card-background)]
          p-2
          sm:p-4
          gap-2
          sm:gap-3
          shadow-md
          max-h-[calc(100vh-18rem)]
          overflow-auto
        "
      >
        <div
          style={{
            display: 'grid',
            gridTemplateRows: `repeat(${rows}, minmax(0, 1fr))`,
            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
            gap: '0.5rem'
          }}
          className="w-full h-auto"
        >
          {letters.map((letter, index) => {
            const isClicked = clickedLetters.includes(index);
            const isCorrect = targetLetters.includes(letter);

            let letterClass = 'bg-gray-200 text-black';
            if (isClicked) {
              letterClass = isCorrect
                ? 'bg-green-500 text-white'
                : 'bg-red-500 text-white';
            }

            return (
              <span
                key={index}
                onClick={() => handleLetterClick(letter, index)}
                className={`
                  font-bold
                  cursor-pointer
                  border
                  border-gray-300
                  rounded-lg
                  flex
                  items-center
                  justify-center
                  transition
                  transform
                  hover:scale-105
                  aspect-square
                  min-w-[1rem]
                  min-h-[1rem]
                  text-xs
                  sm:min-w-[2rem]
                  sm:min-h-[2rem]
                  sm:text-sm
                  md:min-w-[3rem]
                  md:min-h-[3rem]
                  md:text-base
                  lg:text-lg
                  shadow
                  ${letterClass}
                `}
              >
                {letter}
              </span>
            );
          })}
        </div>
      </div>

      {/* DALGA + KONTROL BAR */}
      <div className="w-full h-auto mt-6 relative">
        <div className="absolute top-0 left-0 w-full overflow-hidden leading-[0]">
          <svg
            className="block w-full h-[60px]"
            viewBox="0 0 1440 320"
            preserveAspectRatio="none"
          >
            <path
              fill="#1E293B"
              d="M0,288L34.3,266.7C68.6,245,137,203,206,170.7C274.3,139,343,117,411,128C480,139,549,181,617,213.3C685.7,245,754,267,823,240C891.4,213,960,139,1029,128C1097.1,117,1166,171,1234,160C1302.9,149,1371,75,1406,37.3L1440,0L1440,320L1405.7,320C1371.4,320,1302,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,685,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
            />
          </svg>
        </div>

        <div
          className="
            relative
            bg-slate-800
            text-white
            flex
            items-center
            justify-evenly
            gap-3
            py-2
            px-3
            rounded-t-3xl
            z-10
          "
          style={{ marginTop: '-30px' }}
        >
          {/* Başlat/Duraklat */}
          <button
            onClick={handlePlayPause}
            title={isPlaying ? 'Duraklat' : 'Başlat'}
            className="
              hover:text-yellow-300
              text-xs sm:text-sm md:text-base
              flex flex-col items-center
              focus:outline-none
            "
          >
            <FontAwesomeIcon
              icon={isPlaying ? faPause : faPlay}
              className="text-xl sm:text-2xl md:text-3xl mb-1"
            />
            <span className="text-[0.6rem] sm:text-xs md:text-sm">
              {isPlaying ? 'Duraklat' : 'Başlat'}
            </span>
          </button>

          {/* Yeniden Başlat */}
          <button
            onClick={handleReset}
            title="Yeniden Başlat"
            className="
              hover:text-yellow-300
              text-xs sm:text-sm md:text-base
              flex flex-col items-center
              focus:outline-none
            "
          >
            <FontAwesomeIcon
              icon={faRedo}
              className="text-xl sm:text-2xl md:text-3xl mb-1"
            />
            <span className="text-[0.6rem] sm:text-xs md:text-sm">
              Yeniden Başlat
            </span>
          </button>

          {/* Süre */}
          <div className="flex flex-col items-center" title="Kalan Süre">
            <FontAwesomeIcon
              icon={faStopwatch}
              className="text-xl sm:text-2xl md:text-3xl mb-1"
            />
            <div className="text-[0.6rem] sm:text-xs md:text-sm">
              {timeRemaining} sn
            </div>
          </div>

          {/* Skor */}
          <div className="flex flex-col items-center" title="Skor">
            <FontAwesomeIcon
              icon={faStar}
              className="text-xl sm:text-2xl md:text-3xl mb-1"
            />
            <div className="text-[0.6rem] sm:text-xs md:text-sm">
              {score}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LetterGrid;
