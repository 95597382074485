import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLetterGridGame } from './hooks/useLetterGridGame';
import HeaderSection from './components/HeaderSection';
// import GameControls from './components/GameControls';  // <-- KALDIRILDI
import GameStatus from './components/GameStatus'; // İleride kullanmak isterseniz
import LetterGrid from './components/LetterGrid';
import MessageAlert from './components/MessageAlert'; // İster kullanın ister kaldırın
import GameOverModal from './components/GameOverModal';
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';
import SettingsModal from './components/SettingsModal';
import IntroLayout from './components/IntroLayout';
import './styles/harfIzgarasiStyle.css';
import ExtraInfoSidebar from './components/ExtraInfoSidebar';

const HarfIzgarasiOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  // Kullanıcı verisi ve rol
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Ayarlar (Settings) için modal
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const openSettings = () => setIsSettingsOpen(true);
  const closeSettings = () => setIsSettingsOpen(false);

  // Aşamaya göre zorluk kilitleme
  const stageNumber = stage?.number || null;
  let initialDifficulty = 'easy';
  let difficultyLocked = false;

  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  // Kullanıcı verisini çek
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Öğrenci verisi
        const studentResponse = await axiosInstance.get(
          `${API_URLS.STUDENT_URL}/me`
        );
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yok veya aktif değilse öğretmeni dene
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  // Final skor ve modal yönetimi
  const [finalScore, setFinalScore] = useState(0);
  const [finalCorrectSelections, setFinalCorrectSelections] = useState(0);
  const [finalIncorrectSelections, setFinalIncorrectSelections] = useState(0);
  const [showGameOver, setShowGameOver] = useState(false);

  // Oyun bitince hook callback
  const onGameOver = (score, correctSelections, incorrectSelections) => {
    setShowGameOver(true);
    setFinalScore(score);
    setFinalCorrectSelections(correctSelections);
    setFinalIncorrectSelections(incorrectSelections);
    updateProgress(score);
  };

  // useLetterGridGame Hook
  const {
    letters,
    targetLetters,
    message,
    timeRemaining,
    score,
    isPlaying,
    clickedLetters,
    difficulty,
    gridSize,
    handlePlayPause,
    handleReset,
    handleLetterClick,
    handleDifficultyChange: originalHandleDifficultyChange,
  } = useLetterGridGame(userData, onGameOver, initialDifficulty);

  // Giriş (Intro) ekranı
  const [showInstructions, setShowInstructions] = useState(true);
  const startGame = () => {
    setShowInstructions(false);
  };

  // Tema / Font / Boyut (Ayarlar)
  const [theme, setTheme] = useState('default');
  const handleThemeChange = (e) => setTheme(e.target.value);

  const [font, setFont] = useState('Arial');
  const handleFontChange = (e) => setFont(e.target.value);

  const [fontSize, setFontSize] = useState('1rem');
  const handleFontSizeChange = (e) => setFontSize(e.target.value);

  // İlerlemeyi sunucuya kaydet
  const updateProgress = async (score) => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Harf Izgara',
        score: score,
        time:
          (userData && userData.status === 'inactive' ? 15 : 60) - timeRemaining,
        completed: true,
      };

      if (userRole === 'student') {
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  // Oyun yeniden başlatma
  const handleRestart = () => {
    setShowGameOver(false);
    handleReset();
  };

  // Zorluk değiştirme
  const handleDifficultyChange = (e) => {
    if (difficultyLocked) return;
    originalHandleDifficultyChange(e.target.value);
  };

  // Modal kapatma
  const handleCloseModal = () => {
    setShowGameOver(false);
  };

  // Bir önceki ekrana dön
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <>
      {showInstructions ? (
        /* =========================
           INTRO (TALİMAT) EKRANI
           ========================= */
        <div
          className={`
            w-screen h-screen flex overflow-hidden
            game-container-bg
            bg-[var(--background-color)]
            text-[var(--text-color)]
            items-center justify-center
            theme-${theme}
          `}
        >
          <IntroLayout
            stage={stage}
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            openSettings={openSettings}
            hideSettings={true}
            startGame={startGame}
          />
        </div>
      ) : (
   
        <div
          className={`
            game-container-bg
            w-screen h-screen
            flex flex-col
            overflow-hidden
            bg-[var(--background-color)]
            text-[var(--text-color)]
            theme-${theme}            
          `}
          style={{ fontFamily: font, fontSize }}
        >
          {/* 1) ÜST BAR */}
          <HeaderSection
            stage={stage}
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            openSettings={openSettings}
            hideSettings={false} 
          />

          {/* 2) ORTA ALAN: LetterGrid + Mesajlar + Oyun Sonu Modal vb. */}
          <div className="flex-1 flex flex-col items-center justify-center p-4 relative  ">
            <div className="p-4 rounded-md shadow-md bg-[var(--card-background)]">
              <LetterGrid
                letters={letters}
                targetLetters={targetLetters}
                clickedLetters={clickedLetters}
                handleLetterClick={handleLetterClick}
                gridSize={gridSize}             
                isPlaying={isPlaying}
                handlePlayPause={handlePlayPause}
                handleReset={handleReset}
                timeRemaining={timeRemaining}
                score={score}
              />
            </div>

            {message && (
              <MessageAlert
                message={message}
                className="text-xl font-bold mt-4 text-[#211C84]"
              />
            )}

            {showGameOver && (
              <GameOverModal
                score={finalScore}
                correctCount={finalCorrectSelections}
                incorrectCount={finalIncorrectSelections}
                onRestart={handleRestart}
                onClose={handleCloseModal}
              />
            )}
          </div>

          <SettingsModal
            isOpen={isSettingsOpen}
            onClose={closeSettings}
            difficulty={difficulty}
            handleDifficultyChange={handleDifficultyChange}
            difficultyLocked={difficultyLocked}
            theme={theme}
            handleThemeChange={handleThemeChange}
            font={font}
            handleFontChange={handleFontChange}
            fontSize={fontSize}
            handleFontSizeChange={handleFontSizeChange}
          />
        </div>
      )}
    </>
  );
};

export default HarfIzgarasiOyunu;
