import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import GameHeader from './components/GameHeader';
import GameTimerDisplay from './components/GameTimerDisplay';
import ScoreDisplay from './components/ScoreDisplay';
import GameOverModal from './components/GameOverModal';
import './styles/noktaTakipEt.css'; // Güncellenmiş CSS
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';

function GameSetup() {
  const requestRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [totalTime, setTotalTime] = useState(15);

  // Intro ekranını kontrol eden state
  const [showIntroduction, setShowIntroduction] = useState(true);
  // Intro’dan oyuna geçiş
  const enterGame = () => {
    setShowIntroduction(false);
  };

  // Hız faktörü ve hız değerleri
  const [speedFactor, setSpeedFactor] = useState(1);
  const lineSpeed = 1 * speedFactor;
  const arcSpeed = 0.02 * speedFactor;

  // Oyun alanı parametreleri
  const circleSize = 200; 
  const cx = circleSize / 2;
  const cy = circleSize / 2;
  const radius = (circleSize / 2) - 2;

  // Kardinal noktaların açı değerleri (N, E, S, W)
  const angleN = 3 * Math.PI / 2;
  const angleE = 0;
  const angleS = Math.PI / 2;
  const angleW = Math.PI;

  const cardinals = [
    { name: 'N', angle: angleN, x: cx, y: 0 },
    { name: 'E', angle: angleE, x: circleSize, y: cy },
    { name: 'S', angle: angleS, x: cx, y: circleSize },
    { name: 'W', angle: angleW, x: 0, y: cy }
  ];

  // Noktanın anlık konumu
  const [currentPosition, setCurrentPosition] = useState({
    x: cx, 
    y: 0, 
    state: 'cardinal', 
    cardinal: 'N'
  });

  // Hareket tipi ve hedef
  const [moveType, setMoveType] = useState(null);
  const [target, setTarget] = useState(null);
  const [arcAngle, setArcAngle] = useState(angleN);

  // Skor ve zaman durumları
  const [score, setScore] = useState(0);
  const [gameTime, setGameTime] = useState(totalTime);
  const [initialGameTime, setInitialGameTime] = useState(totalTime);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);

  // Oyuncu verilerini al
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Önce öğrenci mi diye kontrol et
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci değilse öğretmen mi diye kontrol et
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  // Kullanıcı rolüne göre oyun süresini belirle
  useEffect(() => {
    if (userData && userRole) {
      if (userRole === 'student') {
        setTotalTime(userData.status === 'inactive' ? 15 : 40);
      } else if (userRole === 'teacher') {
        setTotalTime(userData.status === 'inactive' ? 15 : 40);
      }
    } else {
      setTotalTime(15);
    }
  }, [userData, userRole]);

  // Oyun süresi (timer) güncellemesi
  useEffect(() => {
    setGameTime(totalTime);
    setInitialGameTime(totalTime);
  }, [totalTime]);

  // Rastgele bir kardinal seç (mevcut konum hariç)
  const getRandomCardinal = (exclude = null) => {
    const filtered = exclude ? cardinals.filter(c => c.name !== exclude) : cardinals;
    return filtered[Math.floor(Math.random() * filtered.length)];
  };

  // Rastgele hareket tipi seç
  const getRandomMoveType = () => {
    return Math.random() < 0.5 ? 'line' : 'arc';
  };

  // Sıradaki hareketi belirle
  const chooseNextMove = (pos) => {
    // Skor arttır
    setScore(prev => prev + 1);

    if (pos.state === 'center') {
      const nextCardinal = getRandomCardinal(); 
      setMoveType('line');
      setTarget({ type: 'cardinal', ...nextCardinal });
    } else if (pos.state === 'cardinal') {
      const nextMove = getRandomMoveType();
      if (nextMove === 'line') {
        setMoveType('line');
        setTarget({ type: 'center' });
      } else {
        const nextCardinal = getRandomCardinal(pos.cardinal);
        setMoveType('arc');
        setTarget({ type: 'cardinal', ...nextCardinal });
        setArcAngle(cardinals.find(c => c.name === pos.cardinal).angle);
      }
    }
  };

  // Oyun süresi geri sayım
  useEffect(() => {
    if (isPlaying && gameTime > 0) {
      const timer = setInterval(() => {
        setGameTime(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (gameTime === 0) {
      setIsPlaying(false);
      setIsGameOver(true);
    }
  }, [isPlaying, gameTime]);

  // Animasyon (requestAnimationFrame) - Noktanın hareketi
  useEffect(() => {
    const animate = () => {
      setCurrentPosition(prev => {
        let { x, y, state, cardinal } = prev;

        // Line (doğrusal) hareket
        if (moveType === 'line' && target && isPlaying) {
          // Hedef 'center' ise
          if (target.type === 'center') {
            if (cardinal === 'N') {
              if (y < cy) {
                y += lineSpeed;
              } else {
                state = 'center';
                cardinal = null;
                chooseNextMove({ x, y, state });
              }
            } else if (cardinal === 'S') {
              if (y > cy) {
                y -= lineSpeed;
              } else {
                state = 'center';
                cardinal = null;
                chooseNextMove({ x, y, state });
              }
            } else if (cardinal === 'E') {
              if (x > cx) {
                x -= lineSpeed;
              } else {
                state = 'center';
                cardinal = null;
                chooseNextMove({ x, y, state });
              }
            } else if (cardinal === 'W') {
              if (x < cx) {
                x += lineSpeed;
              } else {
                state = 'center';
                cardinal = null;
                chooseNextMove({ x, y, state });
              }
            }
          }
          // Hedef 'cardinal' ise
          else if (target.type === 'cardinal') {
            const targetX = target.x;
            const targetY = target.y;
            if (target.name === 'N') {
              if (y > targetY) {
                y -= lineSpeed;
              } else {
                state = 'cardinal';
                cardinal = 'N';
                chooseNextMove({ x, y, state, cardinal });
              }
            } else if (target.name === 'S') {
              if (y < targetY) {
                y += lineSpeed;
              } else {
                state = 'cardinal';
                cardinal = 'S';
                chooseNextMove({ x, y, state, cardinal });
              }
            } else if (target.name === 'E') {
              if (x < targetX) {
                x += lineSpeed;
              } else {
                state = 'cardinal';
                cardinal = 'E';
                chooseNextMove({ x, y, state, cardinal });
              }
            } else if (target.name === 'W') {
              if (x > targetX) {
                x -= lineSpeed;
              } else {
                state = 'cardinal';
                cardinal = 'W';
                chooseNextMove({ x, y, state, cardinal });
              }
            }
          }
        } 
        // Arc (yay) hareketi
        else if (moveType === 'arc' && target && isPlaying) {
          const startAngle = cardinals.find(c => c.name === cardinal).angle;
          const endAngle = target.angle;
          let currentAngle = arcAngle;
          let desiredAngle = endAngle;

          // Açı 2π'ı aşarsa düzeltme
          let finalEnd = desiredAngle;
          if (finalEnd <= startAngle) {
            finalEnd += 2 * Math.PI; 
          }

          if (currentAngle < finalEnd) {
            currentAngle += arcSpeed;
          } else {
            // hedefe ulaştık
            currentAngle = endAngle;
            state = 'cardinal';
            cardinal = target.name;
            chooseNextMove({ x, y, state, cardinal });
          }

          setArcAngle(currentAngle);
          x = cx + Math.cos(currentAngle) * radius;
          y = cy + Math.sin(currentAngle) * radius;
        }

        return { x, y, state, cardinal };
      });

      if (isPlaying && gameTime > 0) {
        requestRef.current = requestAnimationFrame(animate);
      }
    };

    if (isPlaying && gameTime > 0) {
      requestRef.current = requestAnimationFrame(animate);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [moveType, target, arcAngle, isPlaying, gameTime, lineSpeed, arcSpeed]);

  // Buton handler'ları
  const handleGoHome = () => {
    navigate('/');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleRestart = () => {
    setGameTime(totalTime);
    setInitialGameTime(totalTime);
    setScore(0);
    setIsPlaying(false);
    setIsGameOver(false);
    setMoveType(null);
    setTarget(null);
    setArcAngle(angleN);
    setCurrentPosition({ x: cx, y: 0, state: 'cardinal', cardinal: 'N' });
  };

  const handleCloseModal = () => {
    setIsGameOver(false);
  };

  const handleStart = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      if (!moveType || !target) {
        chooseNextMove({ x: cx, y: 0, state: 'cardinal', cardinal: 'N' });
      }
    }
  };

  const handleStop = () => {
    setIsPlaying(false);
  };

 
  const handleSpeedIncrease = () => {
    setSpeedFactor((prev) => parseFloat((prev + 0.1).toFixed(1)));
  };

  const handleSpeedDecrease = () => {
    setSpeedFactor((prev) => {
      const nextValue = prev - 0.1;
      return nextValue < 0.1 ? 0.1 : parseFloat(nextValue.toFixed(1));
    });
  };

  const showScore = userData && userData.status !== 'inactive';

  // Ekrana koşullu olarak intro veya oyun ekranını basıyoruz
  return (
    <div className="dot-tracking-container game-container-bg">
      
      {showIntroduction ? (
    <div className="dot-tracking-intro">
    <div
      className="
        intro-container
        w-full
        max-w-2xl
        mx-auto
        mt-4
        p-4
        md:p-6
      
      "
    >
      <GameHeader
        handleGoHome={handleGoHome}
        handleGoBack={handleGoBack}
        handleReturnToPreviousStage={handleReturnToPreviousStage}
      />
  
      {/* Başlık */}
      <h1 className="intro-title mt-4 text-2xl font-bold mb-2 text-center">
        Nokta Takip Et
      </h1>
  
      {/* Açıklama Metni */}
      <p className="intro-description text-base mb-4">
        Bu egzersizde, ekrandaki noktayı takip edeceksiniz.
        Nokta merkez ve kenar çizgileri boyunca çeşitli yönlere hareket ediyor.
      </p>
  
      {/* Talimatlar Kutusu */}
      <div
        className="
          intro-instructions-box
          bg-white
          p-6
          rounded-md
          shadow
          w-full
          max-w-xl
          mb-4
        "
      >
        <h3
          className="
            intro-instructions-title
            text-lg
            font-semibold
            mb-3
          "
        >
          Talimatlar
        </h3>
        <ol
          className="
            intro-instructions-list
            list-decimal
            list-inside
            pl-5
            text-sm
            text-gray-700
            space-y-2
          "
        >
          <li>Nokta daire etrafında, merkezden kenara veya yay şeklinde hareket eder.</li>
          <li>Başlat butonuna tıklayın; nokta hareket etmeye başlayacak.</li>
          <li>Hareketleri gözünüzle takip edip konsantrasyonunuzu geliştirebilirsiniz.</li>
        </ol>
      </div>
  
      {/* Başlat Butonu */}
      <button
        className="
          intro-button
          bg-blue-600
          hover:bg-blue-700
          text-white
          font-bold
          px-5
          py-2
          rounded-md
          shadow-lg
          transition-transform
          duration-100
          active:scale-95
          mt-4
           block
            mx-auto 
        "
        onClick={enterGame}
      >
        BAŞLAT
      </button>
    </div>
  </div>
  
      ) : (
        <>
          {/* Asıl Oyun Ekranı */}
          <GameHeader 
            handleGoHome={handleGoHome}
            handleGoBack={handleGoBack}
            handleReturnToPreviousStage={handleReturnToPreviousStage}
          />

          <div className="dot-tracking-status">
            <GameTimerDisplay gameTime={gameTime} initialGameTime={initialGameTime} />
            {showScore && <ScoreDisplay score={score} />}
          </div>

          <div className="dot-tracking-circle-container">
            <svg 
              viewBox={`0 0 ${circleSize} ${circleSize}`} 
              className="dot-tracking-circle-svg"
            >
              <circle 
                cx={cx} 
                cy={cy} 
                r={radius} 
                stroke="white" 
                fill="transparent" 
                strokeWidth="2" 
              />
              <line
                x1={0}
                y1={cy}
                x2={circleSize}
                y2={cy}
                stroke="white"
                strokeWidth="2"
              />
              <line
                x1={cx}
                y1={0}
                x2={cx}
                y2={circleSize}
                stroke="white"
                strokeWidth="2"
              />
              <circle 
                cx={currentPosition.x} 
                cy={currentPosition.y} 
                r={8} 
                fill="#ffcc00"
                stroke="#000"
                strokeWidth={2}
              />
            </svg>
          </div>

          <div className="dot-tracking-control-row">
            <div className="dot-tracking-game-controls">
              {!isPlaying && !isGameOver && (
                <button onClick={handleStart}>Başlat</button>
              )}
              {isPlaying && !isGameOver && (
                <button onClick={handleStop}>Durdur</button>
              )}
              <button onClick={handleRestart}>Yeniden Başlat</button>
            </div>

            <div className="dot-tracking-speed-control-container">
              <div className="dot-tracking-speed-label">Hız Ayarı</div>
              <div className="dot-tracking-speed-display">
                <button className="dot-tracking-speed-button" onClick={handleSpeedDecrease}>-</button>
                <div className="dot-tracking-speed-value">{speedFactor.toFixed(1)}</div>
                <button className="dot-tracking-speed-button" onClick={handleSpeedIncrease}>+</button>
              </div>
              <div className="dot-tracking-speed-instructions">
                Hızı 0.5 başlayarak ile hızı artırabilirsiniz.
              </div>
            </div>
          </div>
        </>
      )}

      {isGameOver && (
        <GameOverModal
          score={score}
          onRestart={handleRestart}
          userData={userData}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}

export default GameSetup;
