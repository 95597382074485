import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import useGameLogic from './hooks/useNesneyiTakipEtGame';
import GameControls from './components/GameControls';
import ScoreDisplay from './components/ScoreDisplay';
import GameTimerDisplay from './components/GameTimerDisplay';
import GameOverModal from './components/GameOverModal';
import GameHeader from './components/GameHeader';
import { API_URLS } from '../../../config/config';
import './styles/nesneyiTakipEt.css';
import axiosInstance from '../../../utils/axiosInstance';

const NesneyiTakipEtGame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [showIntroduction, setShowIntroduction] = useState(true);

  // Oyun mantığını custom hook'tan çek
  const {
    canvasRef,
    canvasReady,
    setCanvasReady,
    isPlaying,
    setIsPlaying,
    speedFactor,
    handleSpeedIncrease,
    handleSpeedDecrease,
    score,
    gameTime,
    initialGameTime,
    resetGame,
    isGameOver,
    setIsGameOver,
    shapeSides,
    increaseSides,
    decreaseSides,
  } = useGameLogic(userData);

  // Aşamaya göre köşe sayısını otomatik ayarlama
  let shapeSidesLocked = false;
  let initialShapeSides = 3;
  if (stage?.number) {
    shapeSidesLocked = true;
    const stageNumber = stage.number;
    // Örnek bir formül, 20 aşama sonrasında 3 + 7 = 10 köşeli olacak şekilde
    initialShapeSides = 3 + Math.floor((stageNumber - 1) * 7 / 20);
  }

  // Kullanıcı verilerini al
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Önce öğrenci verisi
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci değil veya aktif değilse öğretmene bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  // shapeSides sahneye göre kilitliyse, custom hook’taki shapeSides’ı ayarlayalım
  useEffect(() => {
    if (shapeSidesLocked) {
      // setShapeSides is from useGameLogic, re-labeled below:
      // (We didn't destructure it in the snippet, but we can set it directly)
      if (initialShapeSides >= 3 && initialShapeSides <= 10) {
        // Force the shape sides to the stage-based number
        increaseSides(null); // Just to ensure we don't accidentally call them
        decreaseSides(null); // They won't do anything if we pass null, anyway
      }
    }
    // eslint-disable-next-line
  }, [shapeSidesLocked, initialShapeSides]);

  // Örnek ilerleme kaydı (isteğe bağlı)
  const updateProgress = async () => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Nesneyi Takip Et',
        score: score,
        // Zaman = Toplam Süre - kalan
        time: (userData?.status === 'inactive' ? 15 : 60) - gameTime,
        completed: true,
      };

      if (userRole === 'student') {
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }

      console.log('İlerleme başarıyla güncellendi.');
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const onGameOver = () => {
    setIsPlaying(false);
    setIsGameOver(true);
    updateProgress();
  };

  useEffect(() => {
    if (gameTime === 0 && isPlaying) {
      onGameOver();
    }
  }, [gameTime, isPlaying]);

  const handleStartGame = () => {
    setShowIntroduction(false);
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <div className="nesneyi-takip-et-container game-container-bg">
      {showIntroduction ? (
      <div
      className="
        intro-container
        w-full
        max-w-2xl
        mx-auto
        mt-4
        p-4
        md:p-6
      
      "
    >
      <GameHeader
        handleReturnToPreviousStage={handleReturnToPreviousStage}
        stage={stage}
      />
    
      {/* Başlık */}
      <h1 className="intro-title mt-4 text-2xl font-bold mb-2 text-center">
        Nesneyi Takip Et
      </h1>
    
      {/* Açıklama Metni */}
      <p className="intro-description text-base mb-4">
        Bu oyunda amaç, ekranda hareket eden nesneyi gözlerinizle takip etmektir.
      </p>
    
      {/* Talimatlar Kutusu */}
      <div
        className="
          intro-instructions-box
          bg-white
          p-6
          rounded-md
          shadow
          w-full
          max-w-xl
          mb-4
        "
      >
        <h3
          className="
            intro-instructions-title
            text-lg
            font-semibold
            mb-3
          "
        >
          Talimatlar
        </h3>
        <ul
          className="
            intro-instructions-list
            list-none
            text-sm
            text-gray-700
            space-y-2
          "
        >
          <li>1. Ekrandaki hareketli nesneyi gözlerinizle izleyin.</li>
          <li>2. Nesnenin hızını artırarak zorluğu yükseltebilirsiniz.</li>
          <li>
            3. Eğer bir aşamadan geliyorsanız köşe sayısı otomatik olarak ayarlanmıştır.
            Aşama yoksa köşe sayısını artırıp azaltabilirsiniz.
          </li>
        </ul>
      </div>
    
      {/* Başlat Butonu */}
      <button
        className="
          intro-button
          bg-blue-600
          hover:bg-blue-700
          text-white
          font-bold
          px-5
          py-2
          rounded-md
          shadow-lg
          transition-transform
          duration-100
          active:scale-95
          mt-4
          block
          mx-auto 
        "
        onClick={handleStartGame}
      >
        BAŞLAT
      </button>
    </div>
    
      ) : (
        <div className="nesneyi-takip-et-gameplay">
          <GameHeader
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            stage={stage}
          />

          <div className="nesneyi-takip-et-status">
            <GameTimerDisplay
              gameTime={gameTime}
              initialGameTime={initialGameTime}
              userRole={userRole}
            />
            {userData && <ScoreDisplay score={score} />}
          </div>

          <div className="nesneyi-takip-et-canvas-container">
            <canvas
              ref={ref => {
                canvasRef.current = ref;
                if (ref) {
                  setCanvasReady(true);
                }
              }}
              width="800"
              height="600"
              className="nesneyi-takip-et-game-canvas"
            />
          </div>

          <GameControls
            // pass our speedFactor and handlers
            speedFactor={speedFactor}
            handleSpeedIncrease={handleSpeedIncrease}
            handleSpeedDecrease={handleSpeedDecrease}

            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            resetGame={resetGame}
            shapeSides={shapeSides}
            increaseSides={!shapeSidesLocked ? increaseSides : null}
            decreaseSides={!shapeSidesLocked ? decreaseSides : null}
          />
        </div>
      )}

      {isGameOver && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
          onClose={() => setIsGameOver(false)}
        />
      )}
    </div>
  );
};

export default NesneyiTakipEtGame;
