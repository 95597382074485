import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import useGameLogic from './hooks/useRotatingDiamondGame';
import GameControls from './components/GameControls';
import ScoreDisplay from './components/ScoreDisplay';
import GameTimerDisplay from './components/GameTimerDisplay';
import GameOverModal from './components/GameOverModal';
import GameHeader from './components/GameHeader'; 
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';
import './styles/rotatingDiamondGame.css';

const RotatingDiamondGame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [showIntroduction, setShowIntroduction] = useState(true);

  // Kullanıcı verilerini (önce öğrenci, yoksa öğretmen) al
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa öğretmen olup olmadığına bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  // Oyun mantığı Hook
  const {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed,
    score,
    gameTime,
    initialGameTime,
    drawDiamond,
    resetGame,
    isGameOver,
    setIsGameOver,
  } = useGameLogic(userData);

  // Aşamalar / Egzersiz için ilerleme güncelleyici
  const updateProgress = async () => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Harf Döngüsü',
        score: score,
        time: (userData.status === 'inactive' ? 15 : 60) - gameTime,
        completed: true,
      };

      if (userRole === 'student') {
        // Öğrenci -> axiosInstance üzerinden token otomatik
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        // Öğretmen -> localStorage token ile Authorization header
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }

      console.log('İlerleme başarıyla güncellendi.');
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  // Oyun bitişi
  const onGameOver = () => {
    setIsPlaying(false);
    setIsGameOver(true);
    updateProgress();
  };

  // Her hız/frame değişiminde çizim
  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(() => {
        drawDiamond();
      }, Math.max(1000 / speed, 100));
      return () => clearInterval(interval);
    }
  }, [isPlaying, speed, drawDiamond]);

  // Geriye sayım sıfırlandığında
  useEffect(() => {
    if (gameTime === 0 && isPlaying) {
      onGameOver();
    }
  }, [gameTime, isPlaying]);

  // Intro ekrandan çık
  const startGame = () => {
    setShowIntroduction(false);
  };

  // Aşamaya / Önceki sayfaya dön
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <div className="rotating-diamond-container game-container-bg">
      {/* Üst Header */}
     
      {showIntroduction ? (
   <div
   className="
     intro-container
     w-full
     max-w-2xl
     mx-auto
     mt-4
     p-4
     md:p-6
   
   "
 >
   <GameHeader
     handleReturnToPreviousStage={handleReturnToPreviousStage}
     stage={stage}
   />
 
   {/* Başlık */}
   <h1 className="intro-title mt-4 text-2xl font-bold mb-2 text-center">
     Harf Döngüsü
   </h1>
 
   {/* Açıklama Metni */}
   <p className="intro-description text-base mb-4">
     Bu egzerside amaç, ekrandaki döngü yapan harfleri takip etmektir.
   </p>
 
   {/* Talimatlar Kutusu */}
   <div
     className="
       intro-instructions-box
       bg-white
       p-6
       rounded-md
       shadow
       w-full
       max-w-xl
       mb-4
     "
   >
     <h3
       className="
         intro-instructions-title
         text-lg
         font-semibold
         mb-3
       "
     >
       Nasıl Oynanır?
     </h3>
     <ul
       className="
         intro-instructions-list
         list-none
         text-sm
         text-gray-700
         space-y-2
       "
     >
       <li>1. Döngü yapan harfleri gözlerinizle izleyin.</li>
       <li>2. Hızı artırarak konsantrasyon sağlayın.</li>
       <li>3. Hızı artırarak zorluğu yükseltin.</li>
     </ul>
   </div>
 
   {/* Başlat Butonu */}
   <button
     className="
       intro-button
       bg-blue-600
       hover:bg-blue-700
       text-white
       font-bold
       px-5
       py-2
       rounded-md
       shadow-lg
       transition-transform
       duration-100
       active:scale-95
       mt-4
       block
       mx-auto 
     "
     onClick={startGame}
   >
     BAŞLAT
   </button>
 </div>
 
      ) : (
        <div className="rotating-diamond-gameplay">
           <GameHeader handleReturnToPreviousStage={handleReturnToPreviousStage}
             stage={stage} 
           />

          <div className="rotating-diamond-status">
            <GameTimerDisplay
              gameTime={gameTime}
              initialGameTime={initialGameTime}
              userRole={userRole}
            />
            {userData && <ScoreDisplay score={score} />}
          </div>

          <div className="rotating-diamond-canvas-container">
            <canvas
              ref={canvasRef}
              width="800"
              height="600"
              className="rotating-diamond-game-canvas"
            />
          </div>

          <GameControls
            speed={speed}
            setSpeed={setSpeed}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            resetGame={resetGame}
          />
        </div>
      )}

      {isGameOver && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
          onClose={() => setIsGameOver(false)}
        />
      )}
    </div>
  );
};

export default RotatingDiamondGame;
