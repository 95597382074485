import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Collapse, Divider, Grid, Card, CardContent, Avatar, Box,
  useTheme, useMediaQuery,} from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, School as SchoolIcon, Settings as SettingsIcon, Logout as LogoutIcon, ExpandLess, ExpandMore, Payment as PaymentIcon,
  Business as BranchIcon, Person as TeacherIcon, Group as StudentsIcon, LibraryBooks as LibraryBooksIcon, AddBox as AddBoxIcon, FormatListBulleted as FormatListBulletedIcon,
  Assignment as AssignmentIcon, LockReset as LockResetIcon, AccountCircle as AccountCircleIcon, AdminPanelSettings as AdminPanelSettingsIcon, ContactPhone as ContactPhoneIcon,
  LocationCity as LocationCityIcon,} from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import OkullarContent from './Okullar';
import Ogretmen from './ogretmen/Ogretmen';
import Ogrenci from './ogrenci/Ogrenci';
 import BookUploadForm from './ogretmen/kutuphane/Add';
import BookListe from './ogretmen/kutuphane/liste/AllListe';
import BenimListe from './ogretmen/kutuphane/liste/BenimListem';  
import Tests from './ogretmen/kutuphane/Test';
import PaymentForm from './payment/PaymentForm';
import PaymentHistory from './payment/PaymentHistory';
import Password from './ayarlar/Password';
import Profil from './ayarlar/Profil';

import useFetchUserData from '../../hooks/kurum/useFetchUserData';
import { API_URLS } from '../../config/config';

const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),
    marginLeft: open ? `${drawerWidth}px` : 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    marginTop: '64px',
    overflow: 'auto',
    height: `calc(100vh - 64px)`,
    backgroundColor: '#f0e7ff',
  })
);

// Material-UI styled component: Üst menü barı
const AppBarStyled = styled((props) => <AppBar {...props} />)(({ theme, open }) => ({
  backgroundColor: '#6a1b9a',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.standard,
  }),
  ...(open && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    },
  }),
}));

// Material-UI styled component: Drawer (yan menü)
const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    background: 'linear-gradient(135deg, #4a148c 0%, #6a1b9a 100%)',
    color: '#fff',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),
  },
}));

// Framer Motion animasyon ayarları
const containerVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -50 },
};

const KurumsalDashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState({});
  const [selectedMenu, setSelectedMenu] = useState('Anasayfa'); 
  const navigate = useNavigate();

  // ----- SAYILARIN STATE'LERI -----
  const [okulSayisi, setOkulSayisi] = useState(0);      // Toplam Okul
  const [ogrenciSayisi, setOgrenciSayisi] = useState(0); // Toplam Öğrenci
  const [ogretmenSayisi, setOgretmenSayisi] = useState(0); // Toplam Öğretmen

  useFetchUserData(setUserData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // -------------- OKUL SAYISI --------------
  useEffect(() => {
    if (!userData?._id) return;

    const fetchSchoolsCount = async () => {
      try {
        const response = await fetch(`${API_URLS.KURUMSAL_URL}schools/${userData._id}`);
        if (!response.ok) {
          throw new Error('Okullar yüklenirken bir hata oluştu.');
        }
        const data = await response.json();
        setOkulSayisi(data.length);
      } catch (err) {
        console.error('Okul sayısı alınırken hata oluştu:', err);
      }
    };

    fetchSchoolsCount();
  }, [userData]);

  // -------------- ÖĞRENCİ SAYISI --------------
  useEffect(() => {
    if (!userData?._id) return;

    const fetchStudentsCount = async () => {
      try {
        const response = await fetch(`${API_URLS.KURUMSAL_URL}student/${userData._id}`);
        if (!response.ok) {
          throw new Error('Öğrenciler yüklenirken bir hata oluştu.');
        }
        const data = await response.json();
        setOgrenciSayisi(data.length);
      } catch (err) {
        console.error('Öğrenci sayısı alınırken hata oluştu:', err);
      }
    };

    fetchStudentsCount();
  }, [userData]);

  // -------------- ÖĞRETMEN SAYISI --------------
  useEffect(() => {
    if (!userData?._id) return;

    const fetchTeachersCount = async () => {
      try {
        const response = await fetch(`${API_URLS.KURUMSAL_URL}teachers/${userData._id}`);
        if (!response.ok) {
          throw new Error('Öğretmenler yüklenirken bir hata oluştu.');
        }
        const data = await response.json();
        setOgretmenSayisi(data.length);
      } catch (err) {
        console.error('Öğretmen sayısı alınırken hata oluştu:', err);
      }
    };

    fetchTeachersCount();
  }, [userData]);

  // Drawer aç/kapa
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Yan menüde tıklanan öğeyi aç/kapa
  const handleClick = (menu) => {
    setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  // Çıkış yap
  const handleLogout = async () => {
    try {
      await fetch(API_URLS.KURUMSAL_URL + 'logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/kurumsal-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  // Yan menü item'ları
  const menuItems = [
    { text: 'Anasayfa', icon: <DashboardIcon />, action: () => setSelectedMenu('Anasayfa') },
    {
      text: 'Bakiye Yükle',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('BakiyeYukle'),
    },
    {
      text: 'Satın Alınan Paketler',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('PaymentHistory'),
    },
    {
      text: 'Kurumlar',
      icon: <SchoolIcon />,
      subMenus: [
        {
          text: 'Okullar',
          icon: <BranchIcon />,
          action: () => setSelectedMenu('Okullar'),
        },
      ],
      action: () => handleClick('Kurumlar'),
    },
    {
      text: 'Öğretmenler',
      icon: <TeacherIcon />,
      action: () => setSelectedMenu('Öğretmenler'),
    },
    {
      text: 'Öğrenciler',
      icon: <StudentsIcon />,
      action: () => setSelectedMenu('Öğrenciler'),
    },
    {
      text: 'Kütüphane',
      icon: <LibraryBooksIcon />,
      subMenus: [
        {
          text: 'Kitap Ekle',
          icon: <AddBoxIcon />,
          action: () => setSelectedMenu('KitapEkle'),
        },
        {
          text: 'Liste',
          icon: <FormatListBulletedIcon />,
          subMenus: [
            {
              text: 'Benim Listem',
              icon: <FormatListBulletedIcon />,
              action: () => setSelectedMenu('BenimListem'),
            },
            {
              text: 'Tüm Liste',
              icon: <FormatListBulletedIcon />,
              action: () => setSelectedMenu('KitapListe'),
            },
          ],
          action: () => handleClick('Liste'),
        },
        {
          text: 'Testler',
          icon: <AssignmentIcon />,
          action: () => setSelectedMenu('Testler'),
        },
      ],
      action: () => handleClick('Kütüphane'),
    },
    {
      text: 'Ayarlar',
      icon: <SettingsIcon />,
      subMenus: [
        {
          text: 'Şifre Güncelle',
          icon: <LockResetIcon />,
          action: () => setSelectedMenu('SifreSet'),
        },
        {
          text: 'Profil',
          icon: <AccountCircleIcon />,
          action: () => setSelectedMenu('Profil'),
        },
        {
          text: 'Yetkilendirme Rolleri',
          icon: <AdminPanelSettingsIcon />,
          action: () => setSelectedMenu('YetkilendirmeRolleri'),
        },
      ],
      action: () => handleClick('Ayarlar'),
    },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  // İçerik render'ı
  const renderContent = () => {
    if (!userData) {
      return <Typography paragraph>Yükleniyor...</Typography>;
    }

    switch (selectedMenu) {
      case 'BakiyeYukle':
        return <PaymentForm userType="Kurumsal" userId={userData._id} />;
      case 'PaymentHistory':
        return <PaymentHistory />;
      case 'Okullar':
        return <OkullarContent />;
      case 'Öğretmenler':
        return <Ogretmen />;
      case 'Öğrenciler':
        return <Ogrenci />;
       case 'KitapEkle':
        return <BookUploadForm />;
      case 'KitapListe':
        return <BookListe />;
      case 'BenimListem':
        return <BenimListe />; 
      case 'Testler':
        return <Tests />;
      case 'SifreSet':
        return <Password />;
      case 'Profil':
        return <Profil />;
      default:
        // ANASAYFA KISMI
        return (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5 }}
          >
            <Card
              sx={{
                background: 'linear-gradient(135deg, #6a1b9a 0%, #8e24aa 100%)',
                color: '#fff',
                mb: 3,
                boxShadow: 4,
                borderRadius: 3,
                padding: 2,
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    mb: 2,
                    fontFamily: 'Roboto, sans-serif',
                    color: '#fff',
                  }}
                >
                  KURUM PROFİLİ
                </Typography>
                <Divider sx={{ borderColor: '#ffffff33' }} />
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: '#8e24aa',
                        color: '#fff',
                        width: 100,
                        height: 100,
                        boxShadow: 4,
                        border: '3px solid #fff',
                      }}
                      component={motion.div}
                      whileHover={{ scale: 1.1 }}
                      transition={{ type: 'spring', stiffness: 300 }}
                    >
                      <SchoolIcon sx={{ fontSize: '3rem' }} />
                    </Avatar>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontFamily: 'Roboto, sans-serif', color: '#fff', fontWeight: 500 }}
                    >
                      {userData.kurum_adi}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#e0e0e0',
                      }}
                    >
                      <ContactPhoneIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                      {userData.telefon}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#e0e0e0',
                      }}
                    >
                      <LocationCityIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                      {userData.sehirAdi} / {userData.ilceAdi}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#e0e0e0',
                      }}
                    >
                      <AccountBalanceWalletIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                      Bakiye: 
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1" align="center" sx={{ color: '#fff' }}>
                      Hesap Durumu: {userData.status === 'active' ? 'Aktif' : 'Pasif'}
                    </Typography>
                  </Grid>

                  {userData.abonelik && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" sx={{ color: '#fff' }}>
                          Abonelik Başlangıç:{' '}
                          {new Date(userData.abonelik.baslangicTarihi).toLocaleDateString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" sx={{ color: '#fff' }}>
                          Abonelik Bitiş:{' '}
                          {new Date(userData.abonelik.bitisTarihi).toLocaleDateString()}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card> 

            {/* Aşağıda 3 Aylık / 6 Aylık / 12 Aylık bakiye, Toplam Okul, Toplam Öğrenci ve Toplam Öğretmen kartları */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* 3 Aylık Bakiye */}
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ backgroundColor: '#e3f2fd' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      3 Aylık Bakiye
                    </Typography>
                    <Typography variant="h4" color="primary">
                      {userData.bakiye3Ay}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* 6 Aylık Bakiye */}
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ backgroundColor: '#e8f5e9' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      6 Aylık Bakiye
                    </Typography>
                    <Typography variant="h4" color="success.main">
                      {userData.bakiye6Ay}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* 12 Aylık Bakiye */}
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ backgroundColor: '#fff3e0' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      12 Aylık Bakiye
                    </Typography>
                    <Typography variant="h4" color="warning.main">
                      {userData.bakiye12Ay}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Toplam Okul Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ backgroundColor: '#e0f7fa' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Toplam Okul
                    </Typography>
                    <Typography variant="h4" color="info.main">
                      {okulSayisi}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Toplam Öğrenci Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ backgroundColor: '#fce4ec' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Toplam Öğrenci
                    </Typography>
                    <Typography variant="h4" color="secondary">
                      {ogrenciSayisi}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Toplam Öğretmen Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ backgroundColor: '#ffe0b2' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Toplam Öğretmen
                    </Typography>
                    <Typography variant="h4" color="primary">
                      {ogretmenSayisi}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </motion.div>
        );
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBarStyled position="fixed" open={drawerOpen}>
        <Toolbar>
          {/* Drawer açma butonu */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* Başlık */}
          <Typography variant="h6" noWrap component="div" sx={{ fontFamily: 'Roboto, sans-serif' }}>
            Kurumsal Panel
          </Typography>
        </Toolbar>
      </AppBarStyled>

      {/* Yan Drawer */}
      <DrawerStyled
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                onClick={() => {
                  item.action && item.action();
                  if (isMobile && !item.subMenus) toggleDrawer();
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: '#8e24aa',
                  },
                  backgroundColor: selectedMenu === item.text ? '#8e24aa' : 'inherit',
                  transition: 'background-color 0.3s',
                }}
              >
                <ListItemIcon sx={{ color: '#fff' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {item.subMenus ? (open[item.text] ? <ExpandLess /> : <ExpandMore />) : null}
              </ListItem>

              {/* Alt Menüleri varsa göster */}
              {item.subMenus && (
                <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subMenus.map((subItem, subIndex) => (
                      <React.Fragment key={subIndex}>
                        <ListItem
                          button
                          onClick={() => {
                            subItem.action && subItem.action();
                            if (isMobile && !subItem.subMenus) toggleDrawer();
                          }}
                          sx={{
                            pl: 4,
                            '&:hover': {
                              backgroundColor: '#8e24aa',
                            },
                            backgroundColor:
                              selectedMenu === subItem.text ? '#8e24aa' : 'inherit',
                            transition: 'background-color 0.3s',
                          }}
                        >
                          <ListItemIcon sx={{ color: '#fff' }}>{subItem.icon}</ListItemIcon>
                          <ListItemText primary={subItem.text} />
                          {subItem.subMenus
                            ? open[subItem.text]
                              ? <ExpandLess />
                              : <ExpandMore />
                            : null}
                        </ListItem>

                        {/* Bir alt seviye subMenus varsa (Liste altındaki BenimListem/TümListe) */}
                        {subItem.subMenus && (
                          <Collapse in={open[subItem.text]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {subItem.subMenus.map((nestedItem, nestedIndex) => (
                                <ListItem
                                  button
                                  key={nestedIndex}
                                  onClick={() => {
                                    nestedItem.action && nestedItem.action();
                                    if (isMobile) toggleDrawer();
                                  }}
                                  sx={{
                                    pl: 6,
                                    '&:hover': {
                                      backgroundColor: '#8e24aa',
                                    },
                                    backgroundColor:
                                      selectedMenu === nestedItem.text ? '#8e24aa' : 'inherit',
                                    transition: 'background-color 0.3s',
                                  }}
                                >
                                  <ListItemIcon sx={{ color: '#fff' }}>
                                    {nestedItem.icon}
                                  </ListItemIcon>
                                  <ListItemText primary={nestedItem.text} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </DrawerStyled>

      {/* İçerik Alanı */}
      <Main open={drawerOpen}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {renderContent()}
        </motion.div>
      </Main>
    </Box>
  );
};

export default KurumsalDashboard;
