import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';

const Instructions = ({
  stage,
  startGame,
  handleReturnToPreviousStage,
  toggleSettingsModal,
  hideSettings
}) => {
  return (
    <div
      className="
        intro-container
        w-full
        max-w-2xl
        mx-auto
        mt-4
        p-4
        md:p-6
      
      "
    >
      <Header
        stage={stage}
        handleReturnToPreviousStage={handleReturnToPreviousStage}
        handleSettingsClick={toggleSettingsModal}
        hideSettings={true}
      />

      <h1 className="intro-title mt-4 text-2xl font-bold mb-2 text-center">
        Takistoskopik Okuma Çalışması
      </h1>

      <p className="intro-description text-base mb-4">
        Takistoskopik Okuma Çalışması, kelimeleri kısa bir süre gösterip ardından hatırladığınız
        kelimeleri yazmanızı sağlar. Bu çalışma, hızlı okuma ve hafıza geliştirme becerilerinizi
        artırmak için tasarlanmıştır.
      </p>

      <div
        className="
          intro-instructions-box
          bg-white
          p-6
          rounded-md
          shadow
          w-full
          max-w-xl
          mb-4
        "
      >
        <h3
          className="
            intro-instructions-title
            text-lg
            font-semibold
            mb-3
            text-[#211C84]
          "
        >
          Talimatlar
        </h3>
        <ul
          className="
            intro-instructions-list
            list-none
            text-sm
            text-gray-700
            space-y-2
          "
        >
          <li>
            1. Kolay, Orta veya Zor seviyelerinden birini seçebilirsiniz.
          </li>
          <li>
            2. "Başlat" butonuna tıklayarak çalışmayı başlatın.
          </li>
          <li>
            3. Ekranda beliren kelimeleri dikkatlice takip edin.
          </li>
          <li>
            4. Gösterim süresi bittikten sonra hatırladığınız kelimeleri giriş alanına yazın.
          </li>
          <li>
            5. "Sonucu Göster" butonuna tıklayarak skorunuzu öğrenin.
          </li>
        </ul>
      </div>

      <button
        className="
          intro-button
          bg-[var(--button-background)]
          hover:bg-[var(--button-hover)]
          text-white
          font-bold
          px-5
          py-2
          rounded-md
          shadow-lg
          transition-transform
          duration-100
          active:scale-95
          block
          mx-auto
        "
        onClick={startGame}
      >
        <FontAwesomeIcon icon={faPlay} /> BAŞLAT
      </button>
    </div>
  );
};

export default Instructions;
