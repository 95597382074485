// components/ExamArea.js

import React, { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';

const ExamArea = ({
  isRunning,
  readingTime,
  paragraf,
  handlePauseResume,
  handleFinishReading,
  loading,
}) => {
  const paragraphRef = useRef(null);

  // Okuma süresini "X dk Y sn" formatında gösteren yardımcı fonksiyon
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes} dk ${seconds} sn`;
  };

  // Otomatik kaydırma (scroll) fonksiyonu
  useEffect(() => {
    if (isRunning && paragraphRef.current) {
      const paragraphElement = paragraphRef.current;
      const totalScrollHeight =
        paragraphElement.scrollHeight - paragraphElement.clientHeight;

      // Paragrafın kelime sayısı
      const wordCount = paragraf.split(' ').length;

      // Ortalama okuma hızı (kelime/dakika)
      const averageReadingSpeed = 200; // isteğe göre değiştirilebilir

      // Kullanıcının bu paragrafı okuması için gereken süre (saniye)
      const totalTimeInSeconds = (wordCount / averageReadingSpeed) * 60;

      const scrollInterval = 100; // her 100 ms'de bir kaydır
      const scrollAmount =
        totalScrollHeight / (totalTimeInSeconds * (1000 / scrollInterval));

      const scrollTimer = setInterval(() => {
        if (paragraphElement.scrollTop < totalScrollHeight) {
          paragraphElement.scrollTop += scrollAmount;
        } else {
          clearInterval(scrollTimer);
        }
      }, scrollInterval);

      return () => clearInterval(scrollTimer);
    }
  }, [isRunning, paragraf]);

  return (
    <div className="flex flex-col items-center justify-center w-full text-center fade-in">
      {/* Zamanlayıcı: Turuncu kutu, siyah yazı */}
      <div className="inline-block bg-[#ff8c00] text-[#1a1a1a] px-5 py-2 text-xl font-bold rounded mb-5">
        Geçen Süre: {formatTime(readingTime)}
      </div>

      {/* Paragraf Alanı: Bir tık daha büyük metin boyutu */}
      <div
        ref={paragraphRef}
        className="
          max-w-[1000px]
          mx-auto
          mb-5
          bg-white
          p-10
          rounded-xl
          overflow-y-auto
          max-h-[500px]
          shadow-lg
          relative
          text-[#333]
          text-justify
          leading-8
          text-lg      /* Mobil için varsayılan metin boyutu (bir tık büyük) */
          sm:text-xl   /* Küçük ekranların üzeri için daha büyük */
          md:text-2xl  /* Orta ekranlar ve üzeri için daha da büyük */
        "
      >
        {loading ? (
          <div>Yükleniyor...</div>
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(paragraf),
            }}
          />
        )}
      </div>

      {/* Kontroller: "Duraklat/Devam Et" ve "Okumayı Bitirdim" butonları */}
      <div className="flex flex-row justify-center gap-3">
        <button
          className="bg-[#ff8c00] text-[#1a1a1a] px-6 py-3 text-base font-medium rounded transition duration-200 hover:bg-orange-600 active:translate-y-0.5"
          onClick={handlePauseResume}
        >
          {isRunning ? 'Duraklat' : 'Devam Et'}
        </button>
        <button
          className="bg-[#ff8c00] text-[#1a1a1a] px-6 py-3 text-base font-medium rounded transition duration-200 hover:bg-orange-600 active:translate-y-0.5"
          onClick={handleFinishReading}
        >
          Okumayı Bitirdim
        </button>
      </div>
    </div>
  );
};

export default ExamArea;
