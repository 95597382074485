import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header'; 
import Instructions from './components/Instructions';
import ExamArea from './components/ExamArea';
import ResultsModal from './components/ResultsModal';
import { API_URLS } from '../../config/config';
import axiosInstance from '../../utils/axiosInstance';

const OkumaSeviyeTesti = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, fromStage, initialTest } = location.state || {};

  const [paragraf, setParagraf] = useState('');
  const [loading, setLoading] = useState(false);
  const [readingTime, setReadingTime] = useState(0);
  const [readingSpeed, setReadingSpeed] = useState(null);
  const [step, setStep] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);
  const [bookId, setBookId] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [showUnrealisticWarning, setShowUnrealisticWarning] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState('');

  /* 1) Kullanıcı verisini (öğrenci/öğretmen) çek */
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Öğrenci verisi
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}me`);
        if (studentResponse.status === 200 && studentResponse.data) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci bulunmadıysa öğretmeni dene
      const token = localStorage.getItem('userToken');
      if (token) {
        try {
          const teacherResponse = await axios.get(`${API_URLS.TEACHER_URL}/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (teacherResponse.status === 200 && teacherResponse.data) {
            setUserData(teacherResponse.data);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, []);

  /* 2) Seçilen zorluk seviyesine göre paragraf çek */
  const fetchParagraf = async (difficulty) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${API_URLS.TESTLER_URL}seviye/${difficulty}`);
      const { content, _id } = response.data;
      setParagraf(content);
      setBookId(_id);
    } catch (error) {
      console.error('Paragraf alınırken hata oluştu:', error);
    } finally {
      setLoading(false);
    }
  };

  /* 3) Zamanlayıcı kontrolü */
  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setReadingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!isRunning && timerRef.current) {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [isRunning]);

  /* 4) Zorluk seçilip "Devam" denildiğinde metni çek ve adımı 1'e al */
  const handleSelectDifficulty = async () => {
    if (!selectedDifficulty) return;
    await fetchParagraf(selectedDifficulty);
    setStep(1);
  };

  /* 5) Sınavı başlat */
  const handleStart = () => {
    setIsRunning(true);
    setStep(2);
  };

  /* 6) Duraklat / Devam */
  const handlePauseResume = () => {
    setIsRunning((prev) => !prev);
  };

  /* 7) Okuma bitti (Sınav tamam) */
  const handleFinishReading = async () => {
    setIsRunning(false);

    const wc = paragraf.split(' ').length;
    const timeInMinutes = readingTime / 60;
    const spd = wc / timeInMinutes;

    setWordCount(wc);
    setReadingSpeed(Math.round(spd));
    setStep(3);

    if (spd > 1000) {
      setShowUnrealisticWarning(true);
      return;
    }

    setShowResultsModal(true);

    // Sonuçları kaydet
    if (userData && userRole) {
      const headers =
        userRole === 'teacher'
          ? { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
          : undefined;

      const payload = {
        wordCount: wc,
        totalTime: readingTime,
        readingSpeed: Math.round(spd),
      };

      try {
        let apiUrl = '';
        // Aşama testi
        if (fromStage && stage) {
          payload.stageNumber = stage.number;
          payload.bookId = bookId;
          apiUrl =
            userRole === 'student'
              ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-stage-test`
              : `${API_URLS.TEACHER_URL}akillikurs/progress/update-stage-test`;
        }
        // İlk hız testi
        else if (initialTest) {
          apiUrl =
            userRole === 'student'
              ? `${API_URLS.STUDENT_URL}akillikurs/test/initial-test`
              : `${API_URLS.TEACHER_URL}akillikurs/test/initial-test`;
        } else {
          return; // Kayıt yok
        }

        if (userRole === 'student') {
          await axiosInstance.post(apiUrl, payload);
        } else {
          await axios.post(apiUrl, payload, { headers });
        }
      } catch (error) {
        console.error('Sonuç kaydedilirken hata oluştu:', error);
      }
    }
  };

  /* 8) Sonuç modalını kapat */
  const handleCloseResultsModal = () => {
    setShowResultsModal(false);
  };

  /* 9) Gerçekçi olmayan hız uyarısında testi başa al */
  const handleRetakeTest = () => {
    setShowUnrealisticWarning(false);
    setReadingTime(0);
    setStep(1);
  };

  return (

    <div className=" game-container-bg w-screen h-screen flex flex-col overflow-hidden bg-[var(--background-color)] text-[var(--text-color)]">
      
      {/* Header - Yatay Üst Bar */}
      <Header />

      {/* İçerik alanı - esneyebilen bölüm */}
      <div className="flex-1 overflow-y-auto p-4 relative">
        
        {/* Adım 0: Zorluk seçimi */}
        {step === 0 && (
          <div className="flex flex-col items-center justify-center mt-10">
            <h2 className="text-2xl font-bold mb-4">Okuma Testi - Zorluk Seçimi</h2>
            <select
              className="border border-gray-300 rounded-md p-2 mb-4"
              value={selectedDifficulty}
              onChange={(e) => setSelectedDifficulty(e.target.value)}
            >
              <option value="">Lütfen bir zorluk seçin</option>
              <option value="easy">Kolay</option>
              <option value="medium">Orta</option>
              <option value="hard">Zor</option>
            </select>
            <button
              className="bg-blue-600 text-white font-bold px-4 py-2 rounded-md shadow hover:bg-blue-700 disabled:opacity-50"
              onClick={handleSelectDifficulty}
              disabled={!selectedDifficulty}
            >
              Devam
            </button>
          </div>
        )}

        {/* Adım 1: Talimatlar */}
        {step === 1 && !showUnrealisticWarning && (
          <>
            {loading ? (
              <p className="text-center mt-10">Metin yükleniyor...</p>
            ) : (
              <Instructions onStart={handleStart} />
            )}
          </>
        )}

        {/* Adım 2: Sınav esnası */}
        {step === 2 && (
          <ExamArea
            isRunning={isRunning}
            readingTime={readingTime}
            paragraf={paragraf}
            handlePauseResume={handlePauseResume}
            handleFinishReading={handleFinishReading}
            loading={loading}
          />
        )}

        {/* Gerçekçi olmayan hız uyarısı */}
        {showUnrealisticWarning && (
          <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center fade-in">
            <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
              <h3 className="text-xl font-bold mb-2 text-red-600">
                Okuma hızınız gerçekçi görünmüyor
              </h3>
              <p className="mb-4 text-gray-700">
                Bu kadar kısa sürede bu metni bu hızla okumanız muhtemelen mümkün değil.
                Lütfen testi yeniden yapınız.
              </p>
              <button
                className="bg-blue-600 text-white font-bold px-4 py-2 rounded-md shadow hover:bg-blue-700"
                onClick={handleRetakeTest}
              >
                Tekrar Dene
              </button>
            </div>
          </div>
        )}

        {/* Sonuç Modalı */}
        {showResultsModal && (
          <ResultsModal
            wordCount={wordCount}
            readingTime={readingTime}
            readingSpeed={readingSpeed}
            userData={userData}
            fromStage={fromStage}
            stage={stage}
            bookId={bookId}
            initialTest={initialTest}
            userRole={userRole}
            onClose={handleCloseResultsModal}
          />
        )}
      </div>
    </div>
  );
};

export default OkumaSeviyeTesti;
